<template>
	<el-tooltip placement="top" content="點擊複製">
		<div ref="slot" class="clipboard" @click="copy">
			<slot />
		</div>
	</el-tooltip>
</template>

<script>
export default {
	methods: {
		async copy() {
			const node = this.$refs["slot"];
			if (document.body.createTextRange) {
				const range = document.body.createTextRange();
				range.moveToElementText(node);
				range.select();
			} else if (window.getSelection) {
				const selection = window.getSelection();
				const range = document.createRange();
				range.selectNodeContents(node);
				selection.removeAllRanges();
				selection.addRange(range);
			} else {
				console.warn("Could not select text in node: Unsupported browser.");
			}
			document.execCommand("copy");
		}
	}
}
</script>